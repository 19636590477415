<template>
  <div>
    <quill-editor
      v-model="content"
      :options="snowOption"
      @blur="trimContent"
    />
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
  },
  props: {
    value: {},
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
        placeholder: this.$t('labels.write_something'),
        preserveWhiteSpace: true,
      },
      content: this.value,
    }
  },
  watch: {
    value($val) {
      this.content = $val
    },
    content($val) {
      this.$emit('input', $val)
    },
  },

  methods: {
    trimContent() {
      this.content = this.content?.replace(/\s+/g,' ')?.trim()
     },
  },

}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/quill.scss';
.ql-editor{
  min-height: 100px;
}
</style>
