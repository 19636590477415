export default {
  methods: {
    afterSubmit(message, routeName, params = {}) {
      this.$bvToast.toast(message, {
        title: this.$t('general.success'),
        variant: 'success',
        solid: true,
        autoHideDelay: 5000,
        appendToast: true,
      })
      setTimeout(() => this.$router.push({
        name: routeName,
        params,
      }), 1000)
    },
    afterSubmitWithoutRedirect(message, params = {}) {
      this.$bvToast.toast(message, {
        title: this.$t('general.success'),
        variant: 'success',
        solid: true,
        autoHideDelay: 5000,
        appendToast: true,
      })
    },
    moduleFilter(searchFields, $val) {
      let $filters = ''
      const $fields = []
      Object.keys(searchFields)
        .forEach(x => {
          if ($fields.indexOf(x) < 0) {
            $fields.push(x)
          }
        })
      $fields.forEach(x => {
        if (x === 'roles') {
          $filters += $val[x] ? `&${x}=[${$val[x]}]` : ''
        } else if (x !== 'roles') {
          $filters += $val[x] ? `&${x}=${$val[x]}` : ''
        }
      })
      return $filters
    },

    getSlug(title) {
      let slug = ''
      // Change to lower case
      const titleLower = title.toLowerCase()

      // Trim the last whitespace
      slug = titleLower.replace(/\s*$/g, '')
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-')

      // regex to remove symbols
      slug = slug.replace(/[!@#\$%\^\&*\)\(+=._?<>]/g, '')

      return slug
    },
  },
}
