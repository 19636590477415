import Vue from 'vue'
import QuillEditor from '@/components/quill-editor/QuillEditorSnow.vue'
import TableIndex from '@/components/tableIndex.vue'
import LocaleTabs from '@/components/LocaleTabs.vue'
import ImagePreview from '@/components/ImagePreview.vue'
import VideoPreview from '@/components/VideoPreview.vue'
import DatePicker from '@/components/DatePicker.vue'
import TimePicker from '@/components/TimePicker.vue'
import LoadingButton from '@/components/LoadingButton.vue'

Vue.component(TableIndex.name, TableIndex)
Vue.component('LocaleTabs', LocaleTabs)
Vue.component('ImagePreview', ImagePreview)
Vue.component('VideoPreview', VideoPreview)
Vue.component('DatePicker', DatePicker)
Vue.component('TimePicker', TimePicker)
Vue.component('LoadingButton', LoadingButton)
Vue.component('QuillEditor', QuillEditor)
