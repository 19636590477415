import axios from '@axios'
import VueAxios from 'vue-axios'

function getLocale() {
  return localStorage.getItem('vuexy-direction')
}
export default {
  install(Vue) {
    axios.defaults.headers.common['Accept-Language'] = getLocale() === 'rtl' ? 'ar' : 'en'
    Vue.use(VueAxios, axios)
  },
}
