<template>
  <div>
    <b-form-group :label="label">
      <b-form-timepicker
        :value="value"
        :placeholder="$t('No time selected')"
        :label-no-time-selected="$t('No time selected')"
        :locale="$store.state.appConfig.layout.direction === 'rtl' ? 'ar' : 'en'"
        :label-close-button="$t('Close')"
        :label-pm="$t('PM')"
        :label-am="$t('AM')"
        @context="onContext"
      />
    </b-form-group>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['label', 'value'],
  methods: {
    onContext(ctx) {
      this.$emit('input', ctx.value)
    },
  },
}
</script>
