<template>
  <div>
    <b-form-group :label="label">
      <b-form-datepicker
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        :value="value"
        reset-button
        :formatted-value="value"
        :locale="$store.state.appConfig.layout.direction === 'rtl' ? 'ar' : 'en'"
        :placeholder="$t('No date selected')"
        :label-no-date-selected="$t('No date selected')"
        :label-help="$t('Use cursor keys to navigate calendar dates')"
        @context="onContext"
      />
    </b-form-group>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['label', 'value'],
  methods: {
    onContext(ctx) {
      this.$emit('input', ctx.selectedYMD)
    },
  },
}
</script>
