import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Localisation language list
// eslint-disable-next-line camelcase
import validate_ar from 'vee-validate/dist/locale/ar.json'
import { localize } from 'vee-validate'
import en from '@/libs/i18n/locales/en.json'
import ar from '@/libs/i18n/locales/ar.json'

Vue.use(VueI18n)

// eslint-disable-next-line camelcase
let messages_text = {}
// eslint-disable-next-line camelcase
messages_text = {
  // eslint-disable-next-line camelcase
  ...messages_text,
  en,
  ar,
}

// get current selected language
const $locale = localStorage.getItem('vuexy-direction') === 'rtl' ? 'ar' : 'en'

function setI18nLanguage(lang) {
  // eslint-disable-next-line no-use-before-define
  i18n.locale = $locale
  if ($locale === 'ar') {
    localize('ar', validate_ar)
  }
  return lang
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: $locale, // set locale
  messages: messages_text, // set locale messages
})
setI18nLanguage($locale)

export default i18n
