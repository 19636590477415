<template>
  <div v-if="loading" class="loader-overlay">

    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style>
/* Add styling for the loader overlay */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 115%;
  height: 100%;
  background: rgb(255 255 255 / 9%);
  //background: rgb(40 48 70 / 64%);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
/* Add styling for the loader */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #9b5ac3;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin-ltr 1s ease-in-out infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>